import React from "react";
import {
  SEO,
  PageLayout,
  Background,
  InternalLink,
  CallToActionBanner,
  Call,
  OutboundLink,
  Content,
  ImageCollection,
  Reviews,
  Map,
} from "@bluefin/components";
import { Header, Grid, Button, Image } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout
          hero={
            <Background columns={2} stackable={true} className={"home-hero"}>
              <Background.Color color={"white"} textAlign={"left"}>
                <Header as={"div"} className={"h6"}>
                  Welcome To
                </Header>
                <Header as={"h1"}>{fishermanBusiness.name}</Header>
                <Grid
                  stackable={true}
                  columns={"equal"}
                  textAlign={""}
                  verticalAlign={"middle"}
                >
                  <Grid.Column>
                    <Button
                      primary={true}
                      size={"large"}
                      to={"/menu/"}
                      as={InternalLink}
                    >
                      View Menu
                    </Button>
                  </Grid.Column>
                  <Grid.Column textAlign={"center"}>
                    <div
                      id={"Tock_widget_container"}
                      data-tock-display-mode={"Button"}
                      data-tock-color-mode={"Blue"}
                      data-tock-locale={"en-us"}
                      data-tock-timezone={"America/Chicago"}
                    />
                  </Grid.Column>
                </Grid>
              </Background.Color>
              <Background.Image
                images={getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "HeroElement",
                })}
              />
            </Background>
          }
          subfooter={false}
          className={"home-page"}
        >
          <CallToActionBanner stackable={true}>
            <CallToActionBanner.CTA>
              <Call
                phoneNumber={fishermanBusiness.primaryLocation.phoneNumber}
                as={"button"}
                buttonColor={"primary"}
              />
            </CallToActionBanner.CTA>
            <CallToActionBanner.CTA>
              <Button
                as={OutboundLink}
                to={"https://www.exploretock.com/osteriainonni/widget/search?"}
                primary={true}
                content={"Book Now"}
                size={"large"}
              />
            </CallToActionBanner.CTA>
          </CallToActionBanner>
          <Content stackable={true}>
            <Content.Image
              src={getComponentFiles({
                components: fishermanBusinessWebsitePage.components,
                componentName: "Content.Image",
                componentIdentifier: "about",
                numToSelect: 1,
              })}
            />
            <Content.Markup isMarkdown={false}>
              <Content.Markup truncate={true} paragraphs={2}>
                {fishermanBusiness.aboutMarkdown}
              </Content.Markup>
              <Image
                src={
                  "https://fisherman.gumlet.io/public/osteriainonni/wine-spectator-award_kykCSUQ.jpeg"
                }
                size={"tiny"}
                as={"a"}
                href={
                  "https://www.winespectator.com/restaurants/5352/osteria-i-nonni"
                }
                target={"_blank"}
              />
              <div className={"info-text"}>
                Thank you for helping us win Wine Spectator's Best of Excellence
                Award for two years running!
              </div>
            </Content.Markup>
          </Content>
          <ImageCollection
            images={getComponentFiles({
              components: fishermanBusinessWebsitePage.components,
              componentName: "ImageCollection",
              numToSelect: 2,
            })}
            as={"gallery"}
            centered={true}
            cutUnevenRows={true}
            smartImageGrouping={false}
            disable={"none"}
            header={""}
            numberOfRowDisplayItems={2}
          />
          <div className={"reviews-container"}>
            <Reviews
              reviews={fishermanBusiness.reviews.slice(0, 3)}
              as={"card"}
              buttonBasic={false}
              buttonSize={"medium"}
              centerHeader={true}
              cardInlinePhoto={"left"}
              header={"Reviews"}
              imageCircular={false}
              imageSize={"small"}
              itemButtonFloat={false}
              itemCenterContent={false}
              itemsDivided={false}
              itemsPerRow={3}
              linkAs={"none"}
              onlyEvenRows={true}
              showImage={true}
              verticalItemAlign={"left"}
            />
            <Button
              to={"/about/"}
              primary={true}
              size={"large"}
              as={InternalLink}
            >
              See More
            </Button>
          </div>
          <Map
            address={{
              street: fishermanBusiness.primaryLocation.street,
              city: fishermanBusiness.primaryLocation.city,
              state: fishermanBusiness.primaryLocation.state,
              zip: fishermanBusiness.primaryLocation.zipCode,
            }}
          />
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      primaryLocation {
        phoneNumber
        street
        city
        state
        zipCode
      }
      aboutMarkdown
      reviews {
        author
        text
        link
      }
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
